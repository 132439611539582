@import '~@angular/material/theming';
@include mat-core();

$color-palette: (
  50: #00796C,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #e78285,
  800: #1565c0,
  900: #7C2D4A,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);


$color-primary: mat-palette($color-palette, 50);
$color-accent:  mat-palette($color-palette, 900, A700);
$color-warn:    mat-palette($mat-deep-orange);
$color-theme: mat-light-theme($color-primary, $color-accent, $color-warn);



@mixin theme-color-grabber($color-theme) {
    // Parse the theme and create variables for each color in the pallete
    $primary: map-get($color-theme, primary);
    $accent: map-get($color-theme, accent);
    $warn: map-get($color-theme, warn);
    // Create theme specfic styles
  
    .active{
      background-color: mat-color($accent);
      color: #ffffff;
    }
  }

//   $custom-typography: mat-typography-config(
//     $font-family: 'Coolvetica'
//   );
  
// @include angular-material-typography($custom-typography);
@include angular-material-theme($color-theme);
@include theme-color-grabber($color-theme);
